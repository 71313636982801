import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import './style.css';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';
import { checkCardNumber, checkCouponeCode } from '../../Services/AuthApi';
import { useAuth } from '../../contexts/AuthContext';
import { notify, notifyError } from '../../components/AlartNotify/AlertNotify';
import { fbEvent, trackEvent } from '../../libs/pixels';
import Card from './Cards';
import { buyGift } from '../../Services/Gift';

const CardSchema = Yup.object().shape({
  card_name: Yup.string()
    .min(2, 'En az 3 karakter olmalıdır')
    .max(50, 'En fazla 50 karakter olmalıdır')
    // .required('İsim zorunlu bir alandır!')
    .matches(/^[A-Za-z\u00C0-\u00FF\u011E\u011F\u0130\u0131\u015E\u015F\u00DC\u00FC_\s]+$/, 'Sadece harf girilebilir'),
  card_number: Yup.string().required('Kart numarası zorunlu bir alandır!'),
  card_date: Yup.string()
    .typeError('Geçerli bir son kullanma tarihi değil. Örnek: AA/YY')
    .max(5, 'Geçerli bir son kullanma tarihi değil. Örnek: AA/YY')
    .matches(/([0-9]{2})\/([0-9]{2})/, 'Geçerli bir son kullanma tarihi değil. Örnek: AA/YY')
    .required('Son kullanım tarihi zorunlu bir alandır!'),
  card_code: Yup.number().required('CVV zorunlu bir alandır!'),
  discount_code: Yup.string(),
});

const initialValues = {
  card_name: '',
  card_surname: '',
  card_number: '',
  card_date: '',
  card_code: '',
  discount_code: '',
};

const SetBankCardGift = (props) => {
  let history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [val, setVal] = useState('');
  const [valDate, setValDate] = useState('');
  const [installment, setInstallment] = useState(12);
  const { selectedPlan } = useAuth();
  const [couponeCode, setCouponeCode] = useState('');
  const [hasCode, setHasCode] = useState(false);
  const [accept, setAccept] = useState(false);
  const [cardHolder, setCardHolder] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [cardMonth, setCardMonth] = useState('');
  const [cardYear, setCardYear] = useState('');
  const [cardCvv, setCardCvv] = useState('');
  const [isCardFlipped, setIsCardFlipped] = useState(false);
  const [giftCardInfo, setGiftCardInfo] = useState(null);

  const onChange = (e) => {
    let code = document.getElementById('card_number').value;
    let card_date = document.getElementById('card_date').value;
    setVal(code);
    setValDate(card_date);
  };

  const onCvvFocus = () => {
    setIsCardFlipped(true);
  };

  const onCvvBlur = () => {
    setIsCardFlipped(false);
  };
  const handleCouponeCode = (e) => {
    setCouponeCode(e.target.value);
    const body = {
      code: e.target.value,
    };
    checkCouponeCode(body).then((res) => {
      setHasCode(res?.success);
    });
  };
  const handleCardNumber = (e) => {
    console.log(selectedPlan);
    setValDate(e.target.value);
    if (e.target.value.length > 6) {
      checkCardNumber(e.target.value.replaceAll(' - ', '').substring(0, 6), selectedPlan?.id).then((res) => {
        setInstallment(res?.max_installment);
      });
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: CardSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      let body = {
        card_number: values.card_number.replaceAll(' - ', ''),
        expiration_month: values.card_date.split('/')[0],
        expiration_year: `20${values.card_date.split('/')[1]}`,
        cvc: values.card_code,
        discount_code: couponeCode,
        installment: installment,
        ...giftCardInfo,
      };
      buyGift(body)
        .then((data) => {
          if (data?.success) {
            localStorage.removeItem('selectedPrice');
            localStorage.removeItem('selectedPlan');
            localStorage.removeItem('gift-card-info');
            notify('Hediyeniz başarıyla gönderildi');
            history.push('/');
          } else {
            notifyError(data?.message);
          }

          setLoading(false);
        })
        .catch((err) => {
          notifyError(err.response.data.error);
          notifyError(err.response.data.message);
          setLoading(false);
        });

      // history.push("/login");
      // notify('Başarı İle Oluşturuldu')
    },
  });

  useEffect(() => {
    const data = localStorage.getItem('gift-card-info');
    if (data) {
      setGiftCardInfo(JSON.parse(data));
    }
    trackEvent('conversion_event_begin_checkout_6', {});
  }, []);

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <section className="sign-in-page slick-bg ws-bg-credit-card" key="Payment">
        <Container className="">
          <Row className="justify-content-center align-items-center ">
            <Col xs="12" md="6" className="mb-5 pb-5">
              <Card
                cardHolder={cardHolder}
                cardNumber={cardNumber}
                cardMonth={cardMonth}
                cardYear={cardYear}
                cardCvv={cardCvv}
                isCardFlipped={isCardFlipped}
              />
            </Col>
            <Col xs="12" md="6" className="align-self-center text-left">
              <div className="sign-user_card ">
                <div className="sign-in-page-data">
                  <div className="sign-in-from w-100 m-auto">
                    <Form onSubmit={formik.handleSubmit} noValidate onChange={onChange}>
                      <Row>
                        <Col md="12">
                          <Form.Group>
                            <Form.Label className="text-white mb-2">Kart Üzerindeki İsim</Form.Label>
                            <Form.Control
                              type="text"
                              className="form-control mb-0"
                              id="card_name"
                              name="card_name"
                              {...formik.getFieldProps('card_name')}
                              placeholder="İsim Gir"
                              autoComplete="off"
                              required
                              onChange={(e) => {
                                formik.setFieldValue('card_name', e.target.value);
                                setCardHolder(e.target.value);
                              }}
                            />
                            {formik.touched.card_name && formik.errors.card_name && (
                              <div className="fv-plugins-message-container">
                                <span className="text-primary" role="alert">
                                  {formik.errors.card_name}
                                </span>
                              </div>
                            )}
                          </Form.Group>
                        </Col>

                        <Col md="12">
                          <Form.Group>
                            <Form.Label className="text-white mb-2">Kart Numarası</Form.Label>
                            <Form.Control
                              type="text"
                              name="card_number"
                              className="form-control mb-0"
                              id="card_number"
                              {...formik.getFieldProps('card_number')}
                              placeholder="0000 - 0000 - 0000 - 0000"
                              autoComplete="off"
                              value={cc_format(val)}
                              required
                              onChange={(e) => {
                                formik.setFieldValue('card_number', cc_format(e.target.value));
                                setCardNumber(cc_format(e.target.value));
                                handleCardNumber(e);
                              }}
                            />
                            {installment < 12 ? (
                              <div className="fv-plugins-message-container">
                                <span className="text-success" role="alert">
                                  {`Bankanız bu kart ile 12 taksite izin vermemektedir. Banka üst sınırı olan ${installment}  taksit ile ödeyebilirsiniz.`}
                                </span>
                              </div>
                            ) : formik.touched.card_number && formik.errors.card_number ? (
                              <div className="fv-plugins-message-container">
                                <span className="text-primary" role="alert">
                                  {formik.errors.card_number}
                                </span>
                              </div>
                            ) : (
                              ''
                            )}
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group>
                            <Form.Label className="text-white mb-2">Son Kullanım Tarihi</Form.Label>
                            <Form.Control
                              type="text"
                              className="form-control mb-0"
                              id="card_date"
                              pattern="\d\d/\d\d"
                              {...formik.getFieldProps('card_date')}
                              name="card_date"
                              placeholder="MM/YY"
                              value={expriy_format(valDate)}
                              autoComplete="off"
                              maxLength={5}
                              required
                              onChange={(e) => {
                                formik.setFieldValue('card_date', e.target.value);
                                setCardMonth(e.target.value.split('/')[0]);
                                setCardYear(e.target.value.split('/')[1]);
                              }}
                            />
                            {formik.touched.card_date && formik.errors.card_date && (
                              <div className="fv-plugins-message-container">
                                <span className="text-primary" role="alert">
                                  {formik.errors.card_date}
                                </span>
                              </div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group>
                            <Form.Label className="text-white mb-2">CVV</Form.Label>
                            <Form.Control
                              type="password"
                              maxLength={3}
                              className="form-control mb-0"
                              id="card_code"
                              name="card_code"
                              placeholder="***"
                              {...formik.getFieldProps('card_code')}
                              autoComplete="off"
                              required
                              onFocus={onCvvFocus}
                              onBlur={onCvvBlur}
                              onChange={(e) => {
                                formik.setFieldValue('card_code', e.target.value);
                                setCardCvv(e.target.value);
                              }}
                            />
                            {formik.touched.card_code && formik.errors.card_code && (
                              <div className="fv-plugins-message-container">
                                <span className="text-primary" role="alert">
                                  {formik.errors.card_code}
                                </span>
                              </div>
                            )}
                          </Form.Group>
                        </Col>
                        {/* <Col sm="12">
                          <Form.Group>
                            <Form.Label className="text-white mb-2">Seçtiğin Plan</Form.Label>
                            <Form.Control
                              type="text"
                              className="form-control mb-0"
                              id="exampleInputcard_type"
                              name="card_plan"

                              autoComplete="off"
                              required
                              disabled
                            />
                          </Form.Group>
                        </Col> */}
                        <Col sm="12">
                          <Form.Group className="kod">
                            <Form.Label className="text-white mb-2">Kupon Kodu</Form.Label>
                            <Form.Control
                              type="text"
                              className={`form-control  mb-0 ${couponeCode?.length > 0 ? (hasCode ? 'valid-code' : 'invalid-code') : ''}`}
                              id="exampleInputcard_discount_code"
                              name="discount_code"
                              {...formik.getFieldProps('discount_code')}
                              autoComplete="off"
                              value={couponeCode}
                              onChange={(e) => handleCouponeCode(e)}
                            />
                            {couponeCode?.length > 0 ? (
                              !hasCode ? (
                                <div>
                                  <span className="invalid-code-icon">
                                    <i className="fa fa-exclamation-circle" /> {/* İkonu seçebilirsiniz */}
                                  </span>
                                  <span className="text-primary" role="alert">
                                    Kupon kodu geçersiz
                                  </span>
                                </div>
                              ) : (
                                <span className="valid-code-icon">
                                  <i className="fa fa-check-circle" /> {/* Geçerli kod ikonu */}
                                </span>
                              )
                            ) : (
                              ''
                            )}
                          </Form.Group>
                        </Col>
                        <Col sm="12" className="mt-4 mb-4">
                          <div className="custom-control custom-checkbox d-inline-block">
                            <input type="checkbox" className="custom-control-input pt-1" id="customCheck" onChange={() => setAccept(!accept)} />
                            <label className="custom-control-label pt-1 pb-1 " htmlFor="customCheck">
                              <Link to="/on-bilgilendirme-formu" target="_blank" className="text-primary">
                                Ön Bilgilendirme Formu
                              </Link>{' '}
                              ve{' '}
                              <Link to="/mesafeli-sozlesme" target="_blank" className="text-primary">
                                Mesafeli Sözleşme
                              </Link>
                              'yi kabul ediyorum.
                            </label>
                          </div>
                        </Col>
                        <Col sm="12" className="mt-2">
                          <div className="custom-control custom-checkbox d-inline-block">
                            * Öde ve Şimdi Keşfet butonuna basarak yukarıda yazılı olan miktarı ödeme yükümlülüğü altına gireceksiniz ve üyeliğiniz
                            siz iptal edene kadar yenilenecektir.
                          </div>
                        </Col>
                        <Col sm="12" className="mt-2 mb-3">
                          <div className="custom-control custom-checkbox d-inline-block">
                            * Gelecekte ücret alınmasını istemiyorsanız{' '}
                            <Link to="/mesafeli-sozlesme" target="_blank" className="text-primary">
                              Mesafeli Sözleşme
                            </Link>{' '}
                            saklı kalmak kaydıyla üyeliğinizi dilediğiniz zaman iptal edebilirsiniz.
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col md="12" className="d-flex justify-content-center">
              <button className="btn btn-transparent mr-2" onClick={() => history.push('/give-gift')}>
                Geri Dön
              </button>
              <button
                // onClick={nextStep}
                type="submit"
                className="btn btn-transparent "
                disabled={!accept}
                onClick={() => formik.handleSubmit()}
              >
                Ödemeyi Tamamla
              </button>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
function cc_format(value) {
  const v = value
    .replace(/\s+/g, '')
    .replace(/[^0-9]/gi, '')
    .substr(0, 16);
  const parts = [];

  for (let i = 0; i < v.length; i += 4) {
    parts.push(v.substr(i, 4));
  }

  return parts.length > 1 ? parts.join(' - ') : value;
}
function expriy_format(value) {
  const expdate = value;
  const expDateFormatter = expdate.replace(/\//g, '').substring(0, 2) + (expdate.length > 2 ? '/' : '') + expdate.replace(/\//g, '').substring(2, 4);

  return expDateFormatter;
}

export default SetBankCardGift;
