import React, { useState } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import './style.css';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { checkGiftCode } from '../../Services/Gift';
import { useAuth } from '../../contexts/AuthContext';
const CardSchema = Yup.object().shape({
  discount_code: Yup.string(),
});

const initialValues = {
  discount_code: '',
};

const IsGiftCoupone = ({ handleClose }) => {
  let history = useHistory();
  const [couponeCode, setCouponeCode] = useState('');
  const [hasCode, setHasCode] = useState(false);
  const { setGiftCodeFunc } = useAuth();

  const formik = useFormik({
    initialValues,
    validationSchema: CardSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setGiftCodeFunc(couponeCode);
      history.push('/coupone-sign-up');
      handleClose();
      // notify('Başarı İle Oluşturuldu')
    },
  });
  const handleCouponeCode = (e) => {
    setCouponeCode(e.target.value);
    const body = {
      code: e.target.value,
    };
    checkGiftCode(body).then((res) => {
      setHasCode(res?.success);
    });
  };

  return (
    <>
      <Row className="justify-content-center align-items-center ">
        <Col xs="12" md="10" className="align-self-center text-left">
          <div className="sign-user_card ">
            <div className="sign-in-page-data">
              <div className="sign-in-from w-100 m-auto">
                <h6 className="mb-3 text-center">Hediye kodunu gir</h6>
                <Form onSubmit={formik.handleSubmit} noValidate>
                  <Row>
                    <Col sm="12">
                      <Form.Group className="kod">
                        <Form.Label className="text-white mb-2">Kupon Kodu</Form.Label>
                        <Form.Control
                          type="text"
                          className={`form-control  mb-0 ${couponeCode?.length > 0 ? (hasCode ? 'valid-code' : 'invalid-code') : ''}`}
                          id="exampleInputcard_discount_code"
                          name="discount_code"
                          {...formik.getFieldProps('discount_code')}
                          autoComplete="off"
                          value={couponeCode}
                          onChange={(e) => handleCouponeCode(e)}
                        />
                        {couponeCode?.length > 0 ? (
                          !hasCode ? (
                            <div>
                              <span className="invalid-code-icon">
                                <i className="fa fa-exclamation-circle" /> {/* İkonu seçebilirsiniz */}
                              </span>
                              <span className="text-primary" role="alert">
                                Kupon kodu geçersiz
                              </span>
                            </div>
                          ) : (
                            <span className="valid-code-icon">
                              <i className="fa fa-check-circle" /> {/* Geçerli kod ikonu */}
                            </span>
                          )
                        ) : (
                          ''
                        )}
                      </Form.Group>
                    </Col>
                  </Row>

                  <button
                    // onClick={nextStep}
                    type="submit"
                    className="btn btn-hover btn-primary1 my-2 w-100"
                    style={{ textTransform: 'none' }}
                    disabled={!hasCode}
                  >
                    Devam Et
                  </button>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default IsGiftCoupone;
