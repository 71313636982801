import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';
import QuestıonItem from './components/QuestıonItem';
import { Link } from 'react-router-dom/cjs/react-router-dom';

export default function Support() {
  const questions = [
    {
      title: 'Wise&Rise nedir?',
      description: `Üyelerimizin ilgi alanlarına göre çeşitli eğitimleri izlemelerini sağlayan, abonelik tabanlı bir dijital eğitim platformudur. 
        İsteyen her kişinin Türkiye’nin en iyileri tarafından verilen yüzlerce dijital eğitimi izlemesini veya dinlemesini mümkün kılar.
        İster iş hayatı ve liderlik, ister fotoğrafçılık, gastronomi, yazarlık, müzik ve daha fazlası olsun; Wise&Rise birinci sınıf bir çevrimiçi öğrenme deneyimi sunar. Eğitimlere her zaman, her yerde ulaşabilirsin.
        `,
    },
    {
      title: 'Dijital Eğitim Platformu Nedir?',
      description:
        'Fiziksel bir yer ya da zaman sınırı olmayan, eğitmenle öğrencinin aynı yerde olmadığı, internet erişimine sahip herkesin kendi seçtiği ortamda akıllı telefon, tablet, bilgisayar gibi araçlarla eğitim alabildiği sistemdir.',
    },
    {
      title: 'Nasıl üye olurum?',
      description:
        'Eğitimlere masaüstü bilgisayar, dizüstü bilgisayar ve mobil uygulamamız gibi çeşitli cihazlardan ve platformlardan erişebilirsiniz. www.wisenrise.com adresinden üyelik işlemlerini gerçekleştirebilir ya da aplikasyon üzerinden üyelik sağlayabilirsin. ',
    },
    {
      title: 'Wise&Rise üyeliği taahhüt gerektiriyor mu?',
      description:
        'Wise&Rise üyeliği hiçbir taahhüt gerektirmez, mevcut faturalandırma döneminin bitiminden itibaren geçerli olmak kaydıyla üyeliğini dilediğin zaman iptal edebilirsin.',
    },
    {
      title: 'Desteklenen cihazlar nelerdir?',
      description:
        'Akıllı telefonlar, tabletler gibi uygulamaya sahip, internete bağlı herhangi bir cihazdan izleyebilirsin. Ayrıca bilgisayarında bir internet tarayıcısı kullanarak da eğitimlere ulaşabilirsin. Web tarayıcısı uyumluluğu için sistem gereksinimlerini inceleyebilir ve en iyi performansı elde etmek için internet hızı önerilerimizi gözden geçirebilirsin. Her plan Wise&Rise’ı aynı anda izleyebileceğin cihaz sayısını belirler. Tüm paketlerde içerikleri HD, FullHD, UHD ya da 4K çözünürlükte izleyebilirsin.',
    },
    {
      title: 'Nasıl ödeme yapabilirim?',
      description: `Ödemelerini banka, kredi kartı, ön ödemeli kart ile wiserise.com üzerinde yer alan ödeme bölümünden Iyzico altyapısı ile güvenli şekilde ödeyebilirsin.
        Ödemelerini Iyzico altyapısıyla güvenli şekilde gerçekleştirebilirsin.
        `,
    },
    {
      title: 'Ödeme yaparken hata alıyorum, bu neden olabilir?',
      description:
        'İnternet alışverişlerine kapalı veya banka tarafından herhangi bir kısıtlaması olan kartlarla ödeme yaparken hata alabilirsin. Bankanla iletişime geçerek bu kısıtlamaları kaldırabilirsin. Sorun yine de çözülmezse bizimle iletişime geçmeni tavsiye ederiz.',
    },
    {
      title: 'Kullanıcı bilgilerimi unuttum, ne yapmam gerekir?',
      description:
        'Üye giriş ekranından “Şifremi Unuttum” butonuna tıkladıktan sonra kayıt esnasında verdiğin e-posta adresini yazarak şifre yenileme linkinin e-posta adresine gönderilmesini sağlayabilirsin.',
    },
    {
      title: 'Eğitimi tamamladıktan sonra da içeriklere erişimim olacak mı?',
      description:
        'Hesabının sorunsuz durumda olması ve eğitimin online olarak var olmaya devam etmesi koşuluyla eğitimlere üyeliğin süresince erişim hakkın bulunmaktadır. Eğitimi tamamladıktan sonra belirli içerikleri incelemeye devam edebilir, notlarına göz gezdirebilir veya eğitimi baştan izleyebilirsin.',
    },

    {
      title: 'Her eğitimde katılım belgesi sağlanıyor mu?',
      description: 'Tamamladığın her eğitim sonunda katılım belgesine hak kazanacaksın.',
    },
    {
      title: 'Platformdaki eğitimler dönemsel olarak mı yayında kalıyor?',
      description:
        'Wise & Rise eğitimleri sürekli platformda kalır, istediğin zaman izleyebilirsin. Wise & Rise mevcut eğitimlerine ek olarak yeni eğitimler ile güncellenir..',
    },

    {
      title: 'Yurtdışı üyeliklerde hangi kartlar geçerli?',
      description:
        'Türkiye dışında yapacağın üyeliklerde Mastercard ya da VISA altyapısı kullanan herhangi bir kredi kartıyla ödemeni tamamlayabilirsin.',
    },
    {
      title: 'Üyelik yenilemesi nasıl yapılır?',
      description:
        'Satın aldığın aylık paketler her ay otomatik olarak yenilenmektedir. Paketinin otomatik olarak yenilenmesini istemiyorsan “Planlarım” alanından yenilenme seçeneklerine ulaşabilir ya da Yardım Merkezimizden destek alabilirsin. ',
    },
    {
      title: 'Taksitli ödeme yapabilir miyim?',
      description:
        'Taksit seçeneğimizin olduğu paketlerde, kredi kartı bilgilerini girdiğinde açılacak taksit penceresinden ödemeni taksitli olarak gerçekleştirebilirsin. ',
    },
    {
      title: 'Yıllık aboneliği taksitli ödeme seçeneği ile satın almak istiyorum, hangi kartları kullanabilirim?',
      description: 'Tüm kredi kartları ile yıllık üyeliğini taksitli ödeme seçeneği ile alabilirsin.',
    },
    {
      title: 'Faturamı nerede görüntüleyebilirim?',
      description: 'Faturalarını görüntülemek için üyelik bilgilerinle giriş yaptıktan sonra “Planlarım” bölümünü ziyaret edebilirsin.',
    },
    {
      title: 'Üyelik iptalini nasıl gerçekleştirebilirim?',
      description:
        'Üyelik iptali işlemlerin için web tarayıcısı üzerinden üyelik bilgilerinle giriş yaptıktan sonra “Planlarım” kısmından “İptal et” butonuna tıklayabilirsin.',
    },
  ];
  const ldJson = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'Nasıl üye olurum?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Hoş geldin! Web sitemizin sağ üst köşesinde yer alan 'Kayıt Ol' adımına tıklayarak kullanıcı adını ve şifreni belirleyebilirsin.",
        },
      },
      {
        '@type': 'Question',
        name: 'İlgimi çeken eğitimleri nasıl deneyebilirim?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Eğitimlerin ilk bölümlerini, içerik hakkında fikir sahibi olabilmek adına eğitim sayfasından ücretsiz bir şekilde izleyebilirsin.',
        },
      },
      {
        '@type': 'Question',
        name: 'Eğitimleri Nasıl İzleyebilirim?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: '‘Hemen Satın Al’ adımını takip ederek seçtiğin eğitimi satın alabilir, dilediğin zaman izlemeye başlayabilirsin.',
        },
      },
      {
        '@type': 'Question',
        name: 'Wise&Rise hesabınızda nasıl giriş yapabilirim?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Oturum Aç'a tıklayın ve e-posta adresini ve şifreni gir. Şifreni unuttuysan, şifremi unuttum diyerek yeni şifre oluşturabilirsin.",
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>
      <div className=" " style={{ paddingTop: '80px' }}>
        <Container style={{ background: '#14161a' }} fluid>
          <Container>
            <Row className="align-items-left">
              <Col sm="12">
                <nav aria-label="breadcrumb" className="text-left iq-breadcrumb-two">
                  <h1 className="h2 title">Sıkça Sorulan Sorular</h1>
                  <span>
                    <Link to="/">Anasayfa</Link> / <span className="text-primary">Sıkça Sorulan Sorular</span>
                  </span>
                </nav>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>

      <section className="mt-5 d-none d-md-block">
        <Container>
          <Row>
            <Col sm="12" className="p-0">
              <div className="ws-card-right d-flex justify-content-between text-left">
                <div className="w-75">
                  <h3 className="my-1">Wise&Rise İş Dünyasında!</h3>
                  <p>Wise&Rise ile kurumunuzda bir öğrenme kültürü oluşturun. Grup indirimleri ve daha fazla bilgi için bizimle iletişime geçin. </p>
                </div>

                <div className="d-flex justify-content-center align-items-center">
                  <Link to="/iletisim" className="btn btn-hover ">
                    BİZİMLE İLERLE{' '}
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Container fluid>
        <main className="site-main">
          <Container>
            <Row>
              <Col lg="12" sm="12">
                <div className="iq-accordion iq-accordion-square">
                  {questions?.map((item, index) => {
                    return <QuestıonItem key={index} item={item} />;
                  })}
                </div>
              </Col>
            </Row>
          </Container>
        </main>
      </Container>
    </>
  );
}
