import React from 'react';
import moment from 'moment';
import bg from '../../assets/images/certificates/sertifika_emty.png';
import style from './style.module.css';

import { useAuth } from '../../contexts/AuthContext';

const CertificateItem = ({ item }) => {
  const { profile } = useAuth();
  return (
    <div className="position-relative certificate_v1">
      <img src={bg} />
      <span className={style.date}>
        {' '}
        {moment(item?.completed_date).format('DD/MM/YYYY')} {}
      </span>
      <div className={style.attended}>
        <span className={style.name}>{profile?.name}</span>
        {/* <span className={`mt-3 ${style.oturum}`}>Oturum Adi</span> */}
        <span className={style.certificateName}>
          {item?.teacher.name} ile {item?.name} oturumuna katılarak bu belgeyi almaya hak kazanmıştır.
        </span>
      </div>

      <span className={style.certificate_number}>CERTIFICATE NUMBER / SERTİFİKA NO: {item?.serial_id}</span>
    </div>
  );
};

export default CertificateItem;
