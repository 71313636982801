import axios from 'axios';

const API = process.env.REACT_APP_BASE_ENDPOIND;


export const buyGift = async (body) => {
    const { data } = await axios.post(`${API}buyGift`, body);
    return data;
};

export const checkGiftCode = async (giftCode) => {
    const { data } = await axios.post(`${API}check-gift-code`, giftCode);
    return data;
};

export const giftCodeRegistration = async (regId, giftCode) => {
    const { data } = await axios.post(`${API}register/gift/${regId}`, giftCode);
    return data;
};
