import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import closeIcon from '../../assets/icons/x.png';
import './style.css';
import { useAuth } from '../../contexts/AuthContext';
import { useApi } from '../../contexts/RequestContext';
import { Modal } from 'react-bootstrap';
import IsGiftCoupone from '../CouponeSignUp/IsGiftCoupone';
import { ModalHeader } from '../ModalHeader';

const LeftMenu = () => {
  const { openMenu, inLogin, profile, isAdmin } = useAuth();
  const { setCat } = useApi();
  const [show, setShow] = useState(false);
  const handleOpenModal = async () => {

    await setShow(true);

  };
  const handleCloseModal = () => {
    setShow(false);
    openMenu();
  };

  return (
    <>
      <div className="menu-page">
        <div className="menu-page-header">
          <img onClick={openMenu} width="30" src={closeIcon} />
        </div>
        <div className="menu-page-body">
          <ul>
            <li className="menu-item">
              <Link onClick={openMenu} to="/" title="Anasayfa">
                Anasayfa
              </Link>
            </li>
            {(!inLogin || (inLogin && profile !== null)) && (
              <li className="menu-item ">
                <Link
                  to="/kategoriler"
                  onClick={() => {
                    setCat('kategoriler');
                    openMenu();
                  }}
                  title={'Kategoriler'}
                >
                  Kategoriler
                </Link>
              </li>
            )}

            {!inLogin && (
              <li className="menu-item">
                <Link onClick={openMenu} to="/uyelik-planlari" title="Planlar">
                  Planlar
                </Link>
              </li>
            )}

            <li className="menu-item">
              <Link onClick={openMenu} to="/business" title="Business">
                Business
              </Link>
            </li>
            <li className="menu-item">
              <Link onClick={openMenu} to="/give-gift" title="Hediye Et">
                Hediye Et
              </Link>
            </li>
            <li className="menu-item">
              <Link onClick={handleOpenModal} to="#" title="Hediye Kuponu Kullan">
                Hediye Kuponu Kullan
              </Link>
            </li>

            {inLogin && profile !== null && (
              <>
                <li className="menu-item">
                  <Link
                    onClick={() => {
                      setCat('kategoriler');
                      openMenu();
                    }}
                    to="/kategoriler"
                    title="Keşfet"
                  >
                    Keşfet
                  </Link>
                </li>
                {/* {isAdmin && (
              <li className="menu-item">
                <Link onClick={openMenu} to="/admin-page">
                  Admin
                </Link>
              </li>
            )} */}
              </>
            )}
            <li className="menu-item">
              <Link onClick={openMenu} to="/iletisim" title="Bize Ulaşın">
                İletişim
              </Link>
            </li>
            <li>
              <Link onClick={openMenu} to="/sikca-sorulan-sorular" title="Sıkça Sorulan Sorular">
                Sıkça Sorulan Sorular
              </Link>
            </li>
            <li>
              <Link onClick={openMenu} to="/iletisim" title="Yardım Merkezi ">
                Yardım Merkezi
              </Link>
            </li>
            <li>
              <Link onClick={openMenu} to="/uyelik-sozlesmesi" title="Abonelik Şartları">
                Abonelik Şartları
              </Link>
            </li>
            <li>
              <Link onClick={openMenu} to="/gizlilik-politikasi" title="Gizlilik Politikası">
                Gizlilik Politikası
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <Modal show={show} onHide={handleCloseModal} centered>
        <Modal.Body style={{ background: '#000000', overflow: 'hidden' }}>
          <ModalHeader handleClose={handleCloseModal} />
          <IsGiftCoupone handleClose={handleCloseModal} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LeftMenu;
