import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import tr from 'date-fns/locale/tr';
import * as Yup from 'yup';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { getPlans } from '../../Services/AuthApi';
import { getCountries } from '../../Services/Country';
import moment from 'moment';
import { useAuth } from '../../contexts/AuthContext';
registerLocale('tr', tr);

const initialValues = {
  email: '',
  phone_number: '',
  country_code: '',
  name: '',
  surname: '',
  sendOption: '',
  scheduledDate: '',
};

export default function GiveaGift() {
  const { setSelectedPlan } = useAuth();
  const [countries, setCountries] = useState([]);
  const [number, setNumber] = useState('');
  const [isSchedule, setIsSchedule] = useState(false);
  const [scheduledDate, setScheduledDate] = useState();
  const [scheduledTime, setScheduledTime] = useState();
  const [giftNote, setGiftNote] = useState('');
  const [name, setname] = useState('');
  const [senderLastName, setSenderLastName] = useState('');
  const [hasInstallment, setHasInstallment] = useState(false);
  const [price, setPrice] = useState([]);
  const history = useHistory();
  const [countryCodeLength, setCountryCodeLength] = useState(10);


  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Lütfen E-posta adresi gir.')
      .min(3, 'En az 3 karakter olmalı!')
      .max(50, 'En fazla 50 karakter olmalı!')
      .required('Lütfen e-posta adresini gir.'),
    phone_number: Yup.string().required('Lütfen telefon numarasını gir.').min(countryCodeLength, 'Lütfen geçerli bir telefon numarası gir.').max(countryCodeLength, 'Lütfen geçerli bir telefon numarası gir.'),
    country_code: Yup.string(),
    name: Yup.string(),
    surname: Yup.string(),
    rname: Yup.string().required('Lütfen gönderdiğin kişinin adını gir.'),
    rsurname: Yup.string().required('Lütfen gönderdiğin kişinin soyadını gir.'),
    remail: Yup.string().email('Lütfen e-posta formatına uygun giriniz.').required('Lütfen e-posta adresini gir.'),
    message: Yup.string(),
  });


  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      console.log(values);
      const data = {
        name: values.name,
        surname: values.surname,
        email: values.email.toLowerCase(),
        rname: values.rname,
        rsurname: values.rsurname,
        remail: values.remail.toLowerCase(),
        country_code: Number(values.country_code),
        phone_number: values.phone_number.replaceAll(' ', '').substr(0, 10),
        message: values.message,
        date: isSchedule ? `${moment(scheduledDate).format('DD.MM.YYYY')} ${moment(scheduledTime).format('HH:mm')}` : moment(new Date()).format('DD.MM.YYYY HH:mm'),
        scheduledDate: scheduledDate,
        scheduledTime: scheduledTime,
        isSchedule: isSchedule,
      };
      localStorage.setItem('selectedPlan', hasInstallment);
      localStorage.setItem('gift-card-info', JSON.stringify(data));
      localStorage.setItem('selectedPrice', hasInstallment ? JSON.stringify(price[2]) : JSON.stringify(price[1]));
      setSelectedPlan(hasInstallment ? price[2] : price[1]);
      history.push('/set-bank-card');
    },
  });

  const getMaxLength = (code) => {
    let country = countries.find((item) => item.dial_code == code);
    setCountryCodeLength(country?.max_length);
    return country?.max_length;
  }


  useEffect(() => {
    const giftCardInfo = localStorage.getItem('gift-card-info');
    if (giftCardInfo) {
      const data = JSON.parse(giftCardInfo);
      formik.setValues(data);
      setname(data?.name);
      setSenderLastName(data?.surname);
      setGiftNote(data?.message);
      setNumber(data?.phone_number);
      setIsSchedule(data?.isSchedule);
      if (data?.isSchedule) {
        setScheduledDate(new Date(data?.scheduledDate));
        setScheduledTime(new Date(data?.scheduledTime));
      }
    }


    getPlans('Yearly')
      .then((res) => {
        setPrice(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    getCountries()
      .then((data) => {
        setCountries(data?.data?.data);
      })
      .catch((err) => {
        console.log(err);
        //notify(err)
      });
    return () => { };

  }, []);
  return (
    <div>
      <div className="pt-5 mt-5">
        <Container>
          <Row className="mb-5">
            <Col md="12">
              <div className="gift-card rounded-3 d-flex justify-content-between">
                <div className="gift-card-image">
                  <div className="gift-card-image-overlay"></div>
                  <img src={require('../../assets/images/bg/topbanner.jpg')} alt="gift-card" />
                </div>
                <div className="gift-card-info w-100">
                  <h4 className="mb-3 mt-3 mt-md-0 mb-md-4 text-start1">Hediye Yıllık Üyelik Planı</h4>
                  <div className="d-flex justify-content-between align-items-between">
                    <Button className={`btn  ${!hasInstallment ? 'btn-white' : 'btn-transparent'} mr-1 mr-md-3`} onClick={() => setHasInstallment(false)}>Peşin Ödeme</Button>
                    <Button className={`btn  ${hasInstallment ? 'btn-white' : 'btn-transparent'}`} onClick={() => setHasInstallment(true)}>Taksitli Ödeme</Button>
                  </div>
                  <div className="gift-card-price pb-3 pt-3">
                    <span className=' gift-card-price-pre-text'>Toplam Fiyat : </span><span className="gift-card-price-text "> ₺{' '}{!hasInstallment ? price[3]?.price : price[2]?.price}</span>
                    {hasInstallment && <span className="text-white">{' '}({price[2]?.name})</span>}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-3 pt-3">
            <Col sm="12" md="6" className="d-flex justify-content-center align-items-center">
              <div className="gift-card-image-container d-flex justify-content-center align-items-center">
                <img
                  src={require('../../assets/images/certificates/yy_dijital_kart2b.jpg')}
                  alt="gift-card"
                  className="img-fluid position-relativex"
                  style={{ maxWidth: '90%', height: 'auto' }}
                />

                <div className="gift-card-image-text">
                  <div className="gift-card-image-note  z-index-1 text-center">{giftNote}</div>
                  <div className="gift-card-image-sender  z-index-1">{name + ' ' + senderLastName}</div>
                </div>
              </div>
            </Col>
            <Col md="6">
              <Form className="mt-4" onSubmit={formik.handleSubmit} noValidate>
                <Row>
                  <Col md="6">
                    {' '}
                    <Form.Group>
                      <Form.Label htmlFor="name" className="custom-label mb-2">
                        Gönderen Adı
                      </Form.Label>
                      <Form.Control
                        type="text"
                        {...formik.getFieldProps('name')}
                        name="name"
                        className="form-control mb-0"
                        id="name"
                        placeholder="Satın alan kişinin adını giriniz"
                        autoComplete="off"
                        required
                        value={name}
                        onChange={(e) => {
                          setname(e.target.value);
                          formik.setFieldValue('name', e.target.value);
                        }}
                      />
                      {formik.touched.name && formik.errors.name && (
                        <div className="fv-plugins-message-container">
                          <span className="text-primary" role="alert">
                            {formik.errors.name}
                          </span>
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Label htmlFor="surname" className="custom-label mb-2">
                      Gönderen Soyadı
                    </Form.Label>
                    <Form.Group className="position-relative">
                      <Form.Control
                        {...formik.getFieldProps('surname')}
                        type="text"
                        name="surname"
                        className="form-control mb-0"
                        id="surnames"
                        placeholder="Satın alan kişinin soyadını giriniz"
                        required
                        value={senderLastName}
                        onChange={(e) => {
                          setSenderLastName(e.target.value);
                          formik.setFieldValue('surname', e.target.value);
                        }}
                      />

                      {formik.touched.surname && formik.errors.surname && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span className="text-primary" role="alert">
                              {formik.errors.surname}
                            </span>
                          </div>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col md="12">
                    {' '}
                    <Form.Group>
                      <Form.Label htmlFor="email" className="custom-label mb-2">
                        Gönderen E-Postası
                      </Form.Label>
                      <Form.Control
                        type="email"
                        {...formik.getFieldProps('email')}
                        name="email"
                        className="form-control mb-0"
                        id="email"
                        placeholder="Satın alan kişinin e-posta adresini giriniz"
                        autoComplete="off"
                        required
                      />
                      {formik.touched.email && formik.errors.email && (
                        <div className="fv-plugins-message-container">
                          <span className="text-primary" role="alert">
                            {formik.errors.email}
                          </span>
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col sm="12" className="">
                    <Form.Label htmlFor="surname" className="custom-label mb-2">
                      Gönderen Telefon Numarası
                    </Form.Label>
                  </Col>
                  <Col xs="4" sm="4" md="3" lg="2" className="mt-1 pr-0">

                    <div className="styleSelect styleSelect-phone">
                      <select
                        className=""
                        data-control="select2"
                        data-hide-search="true"
                        data-placeholder="+90"
                        {...formik.getFieldProps('country_code')}
                        onChange={(e) => {
                          getMaxLength(e.target.value)
                          formik.setFieldValue('country_code', e.target.value)
                        }}
                        name="country_code"
                      >
                        {countries?.map((category) => {
                          return (
                            <option
                              style={{
                                background: '#000',
                                color: '#fff',
                              }}
                              key={category.id}
                              value={category.dial_code}
                            >
                              +{category.dial_code}
                            </option>
                          );
                        })}
                      </select>
                      {formik.touched.country_code && formik.errors.country_code && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span className="text-primary" role="alert">
                              {formik.errors.country_code}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col xs="8" sm="8" md="9" lg="10" className="mt-1">
                    <Form.Control
                      type="tel"
                      className="mb-0 placeholder-color"
                      {...formik.getFieldProps('phone_number')}
                      name="phone_number"
                      id="phone_number"
                      placeholder="(552)5555555"
                      maxLength={countryCodeLength}
                      minLength={countryCodeLength}
                      autoComplete="off"
                      required
                    />

                    {formik.touched.phone_number && formik.errors.phone_number && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span className="text-primary" role="alert">
                            {formik.errors.phone_number}
                          </span>
                        </div>
                      </div>
                    )}
                  </Col>
                  <Col md="6" className='mt-3'>
                    {' '}
                    <Form.Group>
                      <Form.Label htmlFor="rname" className="custom-label mb-2">
                        Gönderilen Adı
                      </Form.Label>
                      <Form.Control
                        type="text"
                        {...formik.getFieldProps('rname')}
                        name="rname"
                        className="form-control mb-0"
                        id="rname"
                        placeholder="Hediye edilen kişinin adı"
                        autoComplete="off"
                        required
                      />
                      {formik.touched.rname && formik.errors.rname && (
                        <div className="fv-plugins-message-container">
                          <span className="text-primary" role="alert">
                            {formik.errors.rname}
                          </span>
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md="6" className='mt-3'>
                    <Form.Group className="position-relative">
                      <Form.Label htmlFor="rsurname" className="custom-label mb-2">
                        Gönderilen Soyadı
                      </Form.Label>
                      <Form.Control
                        {...formik.getFieldProps('rsurname')}
                        type="text"
                        name="rsurname"
                        className="form-control mb-0"
                        id="rsurname"
                        placeholder="Hediye edilen kişinin soyadı"
                        required
                      />

                      {formik.touched.rsurname && formik.errors.rsurname && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span className="text-primary" role="alert">
                              {formik.errors.rsurname}
                            </span>
                          </div>
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md="12">
                    {' '}
                    <Form.Group>
                      <Form.Label htmlFor="remail" className="custom-label mb-2">
                        Gönderilen E-Postası
                      </Form.Label>
                      <Form.Control
                        type="email"
                        {...formik.getFieldProps('remail')}
                        name="remail"
                        className="form-control mb-0"
                        id="remail"
                        placeholder="Hediye edilen kişinin e-posta adresi"
                        autoComplete="off"
                        required
                      />
                      {formik.touched.remail && formik.errors.remail && (
                        <div className="fv-plugins-message-container">
                          <span className="text-primary" role="alert">
                            {formik.errors.remail}
                          </span>
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md="12">
                    <Form.Group>
                      <Form.Label htmlFor="message" className="custom-label mb-2">
                        Hediye notu
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        maxLength={200}
                        {...formik.getFieldProps('message')}
                        name="message"
                        className="form-control mb-0"
                        id="message"
                        placeholder="Hediye mesajını bırakabilirsiniz..."
                        autoComplete="off"
                        value={giftNote}
                        onChange={(e) => {
                          setGiftNote(e.target.value);
                          formik.setFieldValue('message', e.target.value);
                        }}
                      />
                      {formik.touched.message && formik.errors.message && (
                        <div className="fv-plugins-message-container">
                          <span className="text-primary" role="alert">
                            {formik.errors.message}
                          </span>
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md="12" className="gift-card-send-option mt-2">
                    <Form.Group className="w-100 d-flex justify-content-center">
                      <div className="d-flex align-items-center mr-5">
                        <Form.Check
                          type="radio"
                          id="sendNow"
                          value={isSchedule}
                          checked={!isSchedule}
                          name="sendOption"
                          className="form-check-inline me-2"
                          onChange={() => setIsSchedule(false)}
                        />
                        <Form.Label className="custom-label">Hemen Gönder</Form.Label>
                      </div>
                      <div className="d-flex align-items-center">
                        <Form.Check
                          type="radio"
                          id="scheduleSend"
                          value={isSchedule}
                          checked={isSchedule}
                          name="sendOption"
                          className="form-check-inline me-2"
                          onChange={() => setIsSchedule(true)}
                        />
                        <Form.Label className="custom-label">Gönderim Zamanı Belirle</Form.Label>
                      </div>

                    </Form.Group>
                  </Col>
                  <Col md="12">
                    {isSchedule && (
                      <Form.Group className="mt-3 d-flex justify-content-between gift-card-date-picker">
                        <div className="w-100">
                          <DatePicker
                            selected={scheduledDate}
                            onChange={(date) => setScheduledDate(date)}
                            dateFormat="dd.MM.yyyy"
                            locale="tr"
                            className="form-control custom-datepicker"
                            calendarClassName="custom-calendar"
                            placeholderText="Gönderim Tarihi"
                          />
                        </div>
                        <div className="gift-card-time-picker w-100">
                          <DatePicker
                            selected={scheduledTime}
                            onChange={(time) => setScheduledTime(time)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Saat"
                            dateFormat="HH:mm"
                            locale="tr"
                            className="form-control custom-datepicker"
                            calendarClassName="custom-calendar"
                            placeholderText="Gönderim Saati"
                            popperPlacement="bottom-start"
                          />
                        </div>

                        {formik.touched.scheduledDate && formik.errors.scheduledDate && (
                          <div className="fv-plugins-message-container">
                            <span className="text-primary" role="alert">
                              {formik.errors.scheduledDate}
                            </span>
                          </div>
                        )}
                        {formik.touched.scheduledTime && formik.errors.scheduledTime && (
                          <div className="fv-plugins-message-container">
                            <span className="text-primary" role="alert">
                              {formik.errors.scheduledTime}
                            </span>
                          </div>
                        )}
                      </Form.Group>
                    )}
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col md="12" onClick={() => formik.handleSubmit()} className="d-flex justify-content-center mt-5">
              <Button type="submit" className="btn btn-transparent w-25">
                Devam
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </div >
  );
}

function number_format(value) {
  if (/^(?=.*\d)[\d ]+$/.test(value)) {
    const v = value
      .replace(/\s+/g, '')
      .replace(/[^0-9]/gi, '')
      .substr(0, 10);
    const parts = [];

    for (let i = 0; i < v.length; i += 3) {
      parts.push(v.substr(i, 3));
    }
    if (parts.length == 4) return `${parts[0]} ${parts[1]} ${parts[2]}${parts[3]}`;
    return parts.length > 1 ? parts.join(' ') : value;
  } else {
    return '';
  }
}